<template>
  <div style="background: white;">
    <div class="filterBar" style="background: #fff;height: inherit;">
      <div class="party-search">
        <input v-model="searchValue" placeholder="请输入关键字(多个条件请用空格隔开)" @keyup.enter="onSearch" type="search" value="搜索"
          class="search-content" />
        <div class="search-divider"></div>
        <img class="search-icon" :src="require('@/assets/img/search.png')" />
        <van-icon v-if="searchValue" class="clear-btn" @click="onCancel" name="clear" color="#999" size="16" />
        <div class="search-btn" @click="onSearch">搜索</div>
      </div>
    </div>
    <div class="items-button">
      <div class="button-left">签到人数：<span>{{alreadySign}}/{{dataList.length}}</span>人</div>
      <div class="button-right" @click="$router.push({path: '/actives-people-add',query: {id}})">新增</div>
    </div>
    <div class="content">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
        v-if="dataList.length > 0" offset="10">
        <div class="content-item" v-for="item in dataList" :key="item.id">
          <div class="item-left">
            <div class="name">{{item.userName}}</div>
            <div class="mobile">{{item.mobile}}</div>
            <div class="time">{{item.signUpTime}} 报名</div>
          </div>
          <div class="item-right">
            <div class="right-status finished" v-if="item.sign">已签到</div>
            <!-- <div class="right-status unfinished" v-else>未签到</div> -->
            <div class="sign" v-if="!item.sign" @click="helpSign(item.userId)">签到</div>
          </div>
        </div>
      </van-list>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      searchValue: '',
      page: 0,
      limit: 10,
      totalCount: 0,
      loading: false,
      finished: false,
      dataList: [],
      alreadySign: 0
    }
  },
  mounted() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.id = this.$route.query.id
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.page++
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/sign/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          activityId: this.id,
          searchValue: this.searchValue
        })
      }).then(({ data }) => {
        this.$toast.clear()
        if (data.code == 0) {
          // this.pullLoading = false
          this.loading = false;
          this.totalCount = data.page.totalCount
          this.finished = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.alreadySign = data.page.list.filter(item => {
            return item.sign
          }).length
          this.dataList = this.dataList.concat(data.page.list)
        }
      }, err => { this.$toast.clear() })
    },
    helpSign(userId) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/sign/sign'),
        method: 'post',
        params: this.$http.adornParams({
          userId,
          activityId: this.id
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$toast.success({
            message: '签到成功',
            duration: 1500,
            onOpened: () => {
              setTimeout(() => {
                this.$toast.clear()
                this.page = 0
                this.dataList = []
                this.getDataList()
              }, 1500);
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    onSearch() {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.searchValue = ''
      this.page = 0
      this.finished = false
      this.dataList = []
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.filterBar {
  height: 128px;
  padding: 24px 0;
}

.party-search {
  position: relative;
  margin: 0 auto;
  width: 690px;
  height: 64px;
  background: #F5F5F5;
  border-radius: 20px;

  .search-content {
    padding-top: 16px;
    color: #666;
    padding-left: 76px !important;
    display: block !important;
    width: 480px !important;
    margin: 0 122px 0 30px !important;
    background-color: transparent;
    border: none;
  }

  .search-content::placeholder {
    color: #CCCCCC;
  }

  .clear-btn {
    position: absolute;
    top: 16px;
    right: 135px;
  }

  .search-divider {
    width: 2px;
    height: 32px;
    background: #DBDBDB;
    border-radius: 2px;
    position: absolute;
    left: 80px;
    top: 16px;
  }

  .search-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    width: 32px;
    height: 32px;
  }

  .search-btn {
    font-size: 24px;
    border-radius: 20px;
    text-align: center;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 112px;
    height: 56px;
    line-height: 56px;
    background-color: #387FF5;
    color: #fff;
  }
}

.items-button {
  height: 104px;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;

  .button-left {
    font-size: 32px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #666666;

    span {
      color: #333333;
    }
  }

  .button-right {
    width: 112px;
    height: 56px;
    background: #387FF5;
    border-radius: 12px;
    font-size: 24px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 56px;
    text-align: center;
  }
}

.content {
  padding: 0 30px;

  .content-title {
    width: 750px;
    height: 88px;
    background: rgba(255, 255, 255, 0);
    border-radius: 10px 10px 0px 0px;
    font-size: 32px;
    font-family: PingFang-Bold;
    font-weight: 500;
    color: #666666;
    line-height: 88px;

    .count {
      color: #333333;
    }
  }

  .content-item {
    display: flex;
    justify-content: space-between;
    height: 180px;
    border-bottom: 2px solid #EDEDED;

    &:nth-last-child(1) {
      border: none;
    }

    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      padding: 24px 0;

      .name {
        font-size: 28px;
        font-family: PingFang-Bold;
        font-weight: 500;
        color: #333333;
        line-height: 20px;

        .postName {
          color: #666666;
          font-family: PingFangSC-Regular;
        }
      }

      .mobile {
        font-size: 24px;
        font-family: PingFang-Bold;
        font-weight: 500;
        color: #666666;
      }

      .time {
        font-size: 24px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #999999;
      }
    }

    .item-right {
      .right-status {
        width: 112px;
        height: 52px;
        border-radius: 8px;
        font-size: 24px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        line-height: 52px;
        text-align: center;
        margin-top: 24px;
      }

      .sign {
        width: 112px;
        height: 56px;
        background: #387FF5;
        border-radius: 0.16rem;
        font-size: 0.32rem;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.74667rem;
        text-align: center;
        margin-top: 24px;
      }
    }


    .finished {
      background: rgba(242, 76, 72, 0.12);
      color: #387FF5;
    }

    .unfinished {
      background: rgba(102, 102, 102, 0.12);
      color: #666666;
    }
  }
}
</style>